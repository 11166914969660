import type { AlgoliaProductType } from '@integration-layer/modules/05.productServices/runtime/components/WrapperProductTile.props'

export const useRecentlyViewed = () => {
  const algoliaIndex = useAppConfig().currentAlgoliaIndex
  const { skuPrices, fetchSkuPrices } = useProductTileClPrices()

  const recentlyViewedFromCookie = useCookie<string[]>('recently-viewed', {
    default: () => [],
    //max age 5 months
    maxAge: 12960000,
  })

  const getProductsRecentlyViewed = async (maxItemsToDisplay = 4, limit = 3) =>
    await useAsyncData('PRODUCTS_RECENTLY_VIEWED', async () => {
      const products = [...recentlyViewedFromCookie.value]
      const limitedProductCodes =
        products.length > limit
          ? products.slice(0, maxItemsToDisplay)
          : products

      const algoliaProducts = limitedProductCodes.length
        ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
            query: {
              mfcList: limitedProductCodes.join(','),
            },
          })
        : []

      const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)
      const skuCodes = filteredAlgoliaProducts.reduce<string[]>(
        (acc, { size }) => {
          const sku = size?.[0].SKU ?? ''
          acc.push(sku)
          return acc
        },
        []
      )

      await fetchSkuPrices(skuCodes)
      return filteredAlgoliaProducts.map((product): AlgoliaProductType => {
        const skuWithSize = product?.size?.[0].SKU
        return {
          ...product,
          ...(skuWithSize ? { clPrices: skuPrices.value?.[skuWithSize] } : {}),
        }
      })
    })

  return { recentlyViewedFromCookie, getProductsRecentlyViewed }
}
